const isBrowser = typeof window !== `undefined`;

export const authTokenItemName = "authToken";

export function isLoggedIn(): boolean {
  if (!isBrowser) {
    return false;
  }
  const authToken = localStorage.getItem(authTokenItemName);

  return !!authToken;
}

export const loginPath = "/"; // page where login can be performed
export const loginURL = "/api/users/login/";
export const logoutURL = "/api/users/logout/";
export const loginRedirectTo = "/app/pulse"; // page to redirect after a successful login
