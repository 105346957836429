import {
  employerOnboardingStep5,
  fetchAuthInfo,
  login,
  logout,
} from "../actions/authActions";
import { loginPath, loginRedirectTo } from "../../utils/auth";
import { User } from "../../api/usersAPI";
import { createSlice } from "@reduxjs/toolkit";
import { navigate } from "gatsby";
import { employeeOnboarding } from "../actions/onboardingActions";
import { authTokenItemName } from "../../utils/auth";

export interface loginState {
  user?: User;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  errorDetails?: any;
}

const initialState: loginState = {
  user: undefined,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const loginSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.loading = false;
        localStorage.setItem(authTokenItemName, action.payload.token);
        void navigate(loginRedirectTo);
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
          state.errorDetails = action.payload.details;
        } else {
          state.error = true;
          state.errorMessage = undefined;
          state.errorDetails = undefined;
        }
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = undefined;
        state.loading = false;
        localStorage.removeItem(authTokenItemName);

        void navigate(loginPath);
      })
      .addCase(logout.rejected, (state) => {
        // couldn't logout from the server - log out locally by removing the token from the localStorage
        // (let token on the server side expire)
        state.user = undefined;
        state.loading = false;
        localStorage.removeItem(authTokenItemName);

        void navigate(loginPath);
      })
      .addCase(fetchAuthInfo.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(fetchAuthInfo.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchAuthInfo.rejected, (state, action) => {
        // couldn't fetch info of a currently logged in user - force the User to login again
        state.user = undefined;
        state.loading = false;
        localStorage.removeItem(authTokenItemName);

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
          state.errorDetails = action.payload.details;
        }

        void navigate(loginPath);
      })
      .addCase(employerOnboardingStep5, (state, action) => {
        state.user = action.payload.user;
        localStorage.setItem(authTokenItemName, action.payload.token);
        void navigate(loginRedirectTo);
      })
      .addCase(employeeOnboarding.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.loading = false;
        localStorage.setItem(authTokenItemName, action.payload.token);
      })
      .addCase(employeeOnboarding.pending, (state) => {
        state.loading = true;
      })
      .addCase(employeeOnboarding.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default loginSlice.reducer;
