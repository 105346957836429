import { ApiResponse, AsyncThunkConfig } from "../../api/axiosConfig";
import { MinifiedUser, User } from "../../api/usersAPI";
import client from "../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickedTime2Datetime } from "../../utils";
import { handleAxiosError } from "../../utils/redux";
import { dispatchEnqueueSnackbar } from "./notificationsActions";

const activitiesURL = "/api/core/activities/";

export interface CreateActivityData {
  activityTypeId: number;
  attendees: MinifiedUser[];
  closed: boolean;
  description: string;
  endTime: string;
  endDate?: Date;
  location: string;
  owner: User;
  startDate: Date | string;
  startTime: string;
  title: string;
  showToAll?: boolean;
}

export interface ActivityData extends CreateActivityData {
  id?: number;
}

export const createActivity = createAsyncThunk<
  ActivityData,
  CreateActivityData,
  AsyncThunkConfig
>("activities/createActivity", async (data, thunkAPI) => {
  try {
    const { startDate, endDate } = pickedTime2Datetime(
      data.startDate,
      data.startTime,
      data.endTime
    );

    data.startDate = startDate;
    data.endDate = endDate;

    const { startTime, endTime, ...rest } = data;

    const response = await client.post<ApiResponse<ActivityData>>(
      activitiesURL,
      rest
    );

    dispatchEnqueueSnackbar({
      message: "Activity created successfully.",
    });

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const updateActivity = createAsyncThunk<
  ActivityData,
  ActivityData,
  AsyncThunkConfig
>("activities/updateActivity", async (data, thunkAPI) => {
  try {
    const { startDate, endDate } = pickedTime2Datetime(
      data.startDate,
      data.startTime,
      data.endTime
    );

    data.startDate = startDate;
    data.endDate = endDate;

    const { startTime, endTime, ...rest } = data;

    const response = await client.put<ApiResponse<ActivityData>>(
      `${activitiesURL}${data.id || ""}/`,
      rest
    );

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const deleteActivity = createAsyncThunk<
  number,
  number,
  AsyncThunkConfig
>("activities/deleteActivity", async (id, thunkAPI) => {
  try {
    await client.delete(`${activitiesURL}${id}/`);

    return id;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

type changeStatus = {
  id?: number;
  status: string;
};
export const updateActivityStatus = createAsyncThunk<
  undefined,
  changeStatus,
  AsyncThunkConfig
>("activities/updateStatus", async (data, thunkAPI) => {
  try {
    const { id, ...status } = data;

    if (id) {
      await client.post(`${activitiesURL}${id}/set_status/`, status);
    }
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
