import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client, { ApiResponse, AsyncThunkConfig } from "../../api/axiosConfig";
import { Company } from "../../api/companyAPI";
import { handleAxiosError } from "../../utils/redux";

export const clearDepartmentsErrors = createAction("company/clearErrors");

export const fetchCompany = createAsyncThunk<Company, void, AsyncThunkConfig>(
  "company/fetch",
  async (_args, thunkAPI) => {
    try {
      const response = await client.request<ApiResponse<Company>>({
        url: "/api/core/company/",
      });

      return response.data.results;
    } catch (err) {
      return handleAxiosError(err, thunkAPI);
    }
  }
);

export const editCompany = createAsyncThunk<Company, Company, AsyncThunkConfig>(
  "company/edit",
  async (company, thunkAPI) => {
    try {
      const response = await client.put<ApiResponse<Company>>(
        `/api/core/company/`,
        company
      );

      return response.data.results;
    } catch (err) {
      return handleAxiosError(err, thunkAPI);
    }
  }
);
