import { createAsyncThunk } from "@reduxjs/toolkit";
import client, { ApiResponse, AsyncThunkConfig } from "../../api/axiosConfig";
import { ActivityPreferencesResponse } from "../../api/activityPreferencesAPI";
import { User } from "../../api/usersAPI";
import { handleAxiosError } from "../../utils/redux";
import { dispatchEnqueueSnackbar } from "./notificationsActions";

export interface ActivityPreferencesData {
  activityTypes: number[];
  userId: number;
}

export const setActivityTypePreferences = createAsyncThunk<
  ActivityPreferencesResponse,
  ActivityPreferencesData,
  AsyncThunkConfig
>("activityTypes/setActivityTypePreferences", async (args, thunkAPI) => {
  try {
    const response = await client.post<ApiResponse<User>>(
      `/api/users/${args.userId}/set_activity_types/`,
      {
        activityTypesIds: args.activityTypes,
      }
    );

    dispatchEnqueueSnackbar({
      message: "Your preferred Activity Types are saved.",
    });

    return response.data;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
