import { createAction } from "@reduxjs/toolkit";

export interface LimitOffsetFilters {
  limit?: number | null;
  offset?: number | null;
}

export interface StartEndDateFilters {
  startDate?: Date;
  endDate?: Date;
}

export interface UserIdFilters {
  userId?: number;
}

export interface CalendarFilters
  extends LimitOffsetFilters,
    StartEndDateFilters,
    UserIdFilters {}

export const calendarSetLimitOffset = createAction<LimitOffsetFilters>(
  "setCalendarFilters/setLimitOffset"
);

export const calendarSetStartEndDate = createAction<StartEndDateFilters>(
  "setCalendarFilters/setStartEndDate"
);

export const calendarSetUserId = createAction<UserIdFilters | undefined>(
  "setCalendarFilters/setUserId"
);

export const calendarSetFilters = createAction<CalendarFilters>(
  "setCalendarFilters/all"
);
