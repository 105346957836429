import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../api/axiosConfig";
import { LoginResponse, User } from "../../api/usersAPI";
import { loginURL, logoutURL } from "../../utils/auth";
import {
  dispatchGeneralErrorNotification,
  simplyHandleAxiosError,
} from "../../utils/redux";
import { dispatchEnqueueSnackbar } from "./notificationsActions";

export type LoginData = {
  email: string;
  password: string;
};

export const login = createAsyncThunk<
  LoginResponse,
  LoginData,
  AsyncThunkConfig
>("users/login", async (data, thunkAPI) => {
  try {
    const response = await client.post<ApiResponse<LoginResponse>>(
      loginURL,
      data
    );

    return response.data.results;
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      const errResp = err.response.data as ApiError;

      // show error message to the user
      dispatchEnqueueSnackbar({
        message:
          errResp.message === "Validation failed"
            ? "Incorrect credentials. Please enter the correct email address and password."
            : `Your request failed: ${errResp.message}`,
      });

      return thunkAPI.rejectWithValue(errResp);
    }

    // show error message to the user
    dispatchGeneralErrorNotification();
    throw err;
  }
});

export const logout = createAsyncThunk<undefined, undefined, AsyncThunkConfig>(
  "user/logout",
  async (arg_, thunkAPI) => {
    try {
      await client.post(logoutURL);
    } catch (err) {
      return simplyHandleAxiosError(err, thunkAPI);
    }
  }
);

export const fetchAuthInfo = createAsyncThunk<
  User,
  undefined,
  AsyncThunkConfig
>("user/fetchAuthInfo", async (arg_, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<User>>("/api/users/info/");

    return response.data.results;
  } catch (err) {
    return simplyHandleAxiosError(err, thunkAPI);
  }
});

export const employerOnboardingStep5 = createAction<LoginResponse>(
  "user/employerOnboardingStep5"
);

export const employeeOnboardingStep4 = createAction<LoginResponse>(
  "user/employeOnboardingStep4"
);
