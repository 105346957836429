import { createSlice } from "@reduxjs/toolkit";
import { disableUsers } from "../actions/userDisablingActions";

export interface usersState {
  processed: boolean;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: usersState = {
  processed: false,
  loading: true,
  error: false,
  errorMessage: "",
};

const userDisablingSlice = createSlice({
  name: "userDisabling",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(disableUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.processed = true;
      })
      .addCase(disableUsers.pending, (state) => {
        state.loading = true;
        state.processed = false;
      })
      .addCase(disableUsers.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      });
  },
});

export default userDisablingSlice.reducer;
