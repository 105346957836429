import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiError,
  ApiResponse,
  AsyncThunkConfig,
  isAxiosError,
} from "../../api/axiosConfig";
import { Company } from "../../api/companyAPI";
import { OnboardingDepartament } from "../../api/departmentsAPI";
import {
  NewPassword,
  OnboardingEmployer,
  OnboardingUser,
  PartialUser,
} from "../../api/onboardingAPI";
import { User } from "../../api/usersAPI";
import { handleAxiosError } from "../../utils/redux";

interface TokenPart {
  token: string;
}

export const fetchUser = createAsyncThunk<
  TokenPart & { user: OnboardingUser },
  TokenPart,
  AsyncThunkConfig
>("onboarding/fetchUser", async ({ token: activationCode }, thunkAPI) => {
  try {
    const response = await client.post<ApiResponse<{ user: OnboardingUser }>>(
      "/api/users/onboarding/verify/code/",
      {
        activationCode,
      }
    );

    return Object.assign(response.data.results, { token: activationCode });
  } catch (err) {
    if (isAxiosError(err) && err.response != null) {
      return thunkAPI.rejectWithValue(err.response.data as ApiError);
    }

    throw err;
  }
});

export const verifyPhoneNumber = createAsyncThunk<
  void,
  TokenPart & { phoneNumber: string },
  AsyncThunkConfig
>("onboarding/verifyPhoneNumber", async ({ token, phoneNumber }, thunkAPI) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    await client.post(
      "/api/users/onboarding/verify/phone/",
      {
        phoneNumber,
      },
      config
    );
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const usersOnboarding = createAsyncThunk<
  TokenPart & { user: User },
  OnboardingEmployer & TokenPart,
  AsyncThunkConfig
>(
  "onboarding/usersOnboarding",
  async (
    { token, company, user, password, organizationStructure },
    thunkAPI
  ) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await client.post<
        ApiResponse<{ user: User } & TokenPart>
      >(
        "/api/users/onboarding/",
        {
          company,
          user,
          password,
          organizationStructure: organizationStructure?.reduce(
            (acc, { name, teams }) =>
              Object.assign(acc, {
                [name]: teams.map((team) => team.name),
              }),
            {}
          ),
        },
        config
      );

      return response.data.results;
    } catch (err) {
      return handleAxiosError(err, thunkAPI);
    }
  }
);

export const setSteps = createAction<number>("onboarding/setSteps");
export const setCurrentStep = createAction<number>("onboarding/setCurrentStep");
export const employerOnboardingStep1 = createAction<Company>(
  "onboarding/employerOnboardingStep1"
);
export const employerOnboardingStep2 = createAction<PartialUser>(
  "onboarding/employerOnboardingStep2"
);
export const employerOnboardingStep3 = createAction<string>(
  "onboarding/employerOnboardingStep3"
);
export const employerOnboardingStep4 = createAction<OnboardingDepartament[]>(
  "onboarding/employerOnboardingStep4"
);

export const employeeOnboardingStep1 = createAction(
  "onboarding/employeeOnboardingStep1"
);

export const employeeOnboardingStep2 = createAction(
  "onboarding/employeeOnboardingStep2"
);

export const employeeOnboardingStep3 = createAction(
  "onboarding/employeeOnboardingStep3"
);

export const onboardingGDPR = createAction("onboarding/employeeOnboardingGDPR");

export const employeeOnboarding = createAsyncThunk<
  TokenPart & { user: User },
  TokenPart & NewPassword,
  AsyncThunkConfig
>("employee/usersOnboarding", async ({ token, newPassword }, thunkAPI) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const response = await client.post<ApiResponse<{ user: User } & TokenPart>>(
      "/api/users/create_password/",
      { newPassword },
      config
    );

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const showWelcomeMessage = createAction("onboarding/showWelcomeMessage");
export const hideWelcomeMessage = createAction("onboarding/hideWelcomeMessage");
