import { createMuiTheme } from "@material-ui/core/styles";

export const primaryColor = "#0C3DEB";
export const primaryColorHover = "#5577F1";
export const primaryColorPressed = "#082BA5";
export const primaryColorDisabled = "#0C3DEB4D";
export const secondaryColor = "#FFFFFF";
export const secondaryColorHover = "#F8F9FA";

export const backgroundGeneralColor = "#F7F8F9";
export const backgroundBadgeColor = "#e4e9f9";

export const fontColorPrimary = "#000000";
export const fontColorSecondary = "#ffffff";
export const fontColorTertiary = "#707688";

export const warningColor = "#DD5C23";
export const warningColorHover = "#ED8658";
export const warningColorFocused = "#BE4813";
export const warningColorDisabled = "#DD5C234D";
export const successColor = "#34CB04";

export const borderColor = "#EAECEF";
export const inputBackgroundColor = "#F4F5F7";
export const inputMobileBackgroundColor = "#FFFFFF";
export const inputBorderBottomColor = "#E1E5EB";
export const inactiveCheckboxColor = "#97A1B0";
export const textInputBackground = "#F4F5F7";

export const modalBackgroundColor = "rgba(12, 61, 235, 0.5)";
export const menuSelectedHighlight = "rgba(12, 61, 235, 0.1)";

export const pulseFormBackground = "#EEF0F3";

export const theme = createMuiTheme({
  breakpoints: {
    //  keep consistent with ../styles/variables.scss
    values: {
      xs: 0,
      sm: 500,
      md: 768,
      lg: 1024,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    warning: {
      main: warningColor,
    },
  },
});

export const typographyButtons = {
  "font-size": "16px",
  "letter-spacing": "0.5px",
  "line-height": "24px",
  "font-weight": "500",
};
