import { User } from "../../api/usersAPI";
import { createSlice } from "@reduxjs/toolkit";
import { fetchNetworkUsers } from "../actions/networkUsersActions";

export interface NetworkUsersState {
  users: User[];
  userCount: number;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: NetworkUsersState = {
  users: [],
  userCount: 0,
  loading: true,
  error: false,
  errorMessage: "",
};

const NetworkUsersSlice = createSlice({
  name: "networkUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNetworkUsers.fulfilled, (state, action) => {
        state.users = action.payload.results;
        state.userCount = action.payload.count || 0;
        state.loading = false;
      })
      .addCase(fetchNetworkUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNetworkUsers.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      });
  },
});

export default NetworkUsersSlice.reducer;
