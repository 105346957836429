import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleExtendedAxiosError} from "../../../utils/redux";
import client, {
  ApiResponse,
  ExtendedAsyncThunkConfig,
} from "../../../api/axiosConfig";
import { ApiComments, Comment, commentsURL } from "../../../api/pulseAPI";
import { LimitOffsetFilters } from "../calendarFiltersActions";


interface CommentResponse extends ApiComments {
  nextParams?: LimitOffsetFilters;
}

interface CommentResponseData<T> {
  postId: number;
  data: T;
}

export interface CommentParams extends LimitOffsetFilters {
  post: number;
}

export interface CommentCreateData {
  post: number;
  body: string;
}

export interface CommentEditData extends CommentCreateData {
  id: number;
}

export const fetchComments = createAsyncThunk<
  CommentResponseData<CommentResponse>,
  CommentParams,
  ExtendedAsyncThunkConfig
>("pulse/comments/fetch", async (params, thunkAPI) => {
  try {
    const response = await client.get<CommentResponse>(commentsURL, { params });

    return {postId: params.post, data: response.data};
  } catch (error) {
    return handleExtendedAxiosError({err: error, extendedPayload: {postId: params.post}}, thunkAPI);
  }
});

export const addComment = createAsyncThunk<
  CommentResponseData<Comment>,
  CommentCreateData,
  ExtendedAsyncThunkConfig
>("pulse/comments/create", async (data, thunkAPI) => {
  try {
    const response = await client.post<ApiResponse<Comment>>(commentsURL, data);

    return {postId: data.post, data: response.data.results};
  } catch (error) {
    return handleExtendedAxiosError({err: error, extendedPayload: {postId: data.post}}, thunkAPI);
  }
});

export const deleteComment = createAsyncThunk<CommentResponseData<
  { commentId: number }>,
  { post: number, commentId: number },
  ExtendedAsyncThunkConfig
>(
  "pulse/comments/delete",
  async ({post, commentId}, thunkAPI) => {
    try {
      await client.delete(`${commentsURL}${commentId}/`);

      return {postId: post, data: {commentId}};
    } catch (error) {
      return handleExtendedAxiosError({err: error, extendedPayload: {postId: post}}, thunkAPI);
    }
  }
);

export const editComment = createAsyncThunk<
  CommentResponseData<Comment>,
  CommentEditData,
  ExtendedAsyncThunkConfig
>("pulse/comments/edit", async (data, thunkAPI) => {
  try {
    const response = await client.patch<ApiResponse<Comment>>(
      `${commentsURL}${data.id}/`,
      {body: data.body}
    );

    return {postId: data.post, data: response.data.results};
  } catch (error) {
    return handleExtendedAxiosError({err: error, extendedPayload: {postId: data.post}}, thunkAPI);
  }
});
