import {
  ActivitiesParams,
  ActivityType,
  ApiActivities,
} from "../../api/activitiesAPI";
import { ApiResponse, AsyncThunkConfig } from "../../api/axiosConfig";
import client from "../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import store from "../store";
import { CalendarFilters } from "./calendarFiltersActions";
import { handleAxiosError } from "../../utils/redux";

const prepareActivitiesFetchParameters = (filters: CalendarFilters) => {
  return {
    // eslint-disable-next-line camelcase
    start_date__gte: filters.startDate,
    // eslint-disable-next-line camelcase
    start_date__lte: filters.endDate,
    limit: filters.limit,
    offset: filters.offset,
    userId: filters.userId,
  } as ActivitiesParams;
};

export const fetchActivities = createAsyncThunk<
  ApiActivities,
  void,
  AsyncThunkConfig
>("activities/fetchActivities", async (_, thunkAPI) => {
  try {
    const params = prepareActivitiesFetchParameters(
      store.getState().calendarFilters
    );

    const response = await client.request<ApiActivities>({
      url: "/api/core/activities/",
      params,
    });

    if (params && params.limit && params.offset) {
      response.data.toAppend = true;
    }

    return response.data;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

const activityTypeURL = "/api/core/activity-types/";

export const fetchActivityTypes = createAsyncThunk<
  ActivityType[],
  void,
  AsyncThunkConfig
>("activities/fetchActivityTypes", async (_args, thunkAPI) => {
  try {
    const response = await client.get<ApiResponse<ActivityType[]>>(
      activityTypeURL
    );

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
