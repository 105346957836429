import { createSlice } from "@reduxjs/toolkit";
import {
  changeAttendees,
  removeAllAttendees,
} from "../actions/activityFormActions";

export interface ActivityFormState {
  attendeesIds: number[];
}

const initialState: ActivityFormState = {
  attendeesIds: [],
};

const activitiesFormReducer = createSlice({
  name: "activitiesForm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeAttendees, (state, action) => {
      state.attendeesIds = action.payload;
    });
    builder.addCase(removeAllAttendees, (state) => {
      state.attendeesIds = [];
    });
  },
});

export default activitiesFormReducer.reducer;
