import { createSlice } from "@reduxjs/toolkit";
import { fetchActivityTypes } from "../actions/activityTypesActions";
import { ActivityType } from "../../api/activityTypesAPI";

export interface ActivityTypesState {
  activityTypes: ActivityType[];
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: ActivityTypesState = {
  activityTypes: [],
  loading: true,
  error: false,
  errorMessage: "",
};

const activityTypesSlice = createSlice({
  name: "activityTypes",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityTypes.fulfilled, (state, action) => {
        state.activityTypes = action.payload.results;
        state.loading = false;
      })
      .addCase(fetchActivityTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActivityTypes.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      });
  },
});

export default activityTypesSlice.reducer;
