import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { ErrorPayloadAction } from "../../utils/redux";
import { Company } from "../../api/companyAPI";
import { OnboardingDepartament } from "../../api/departmentsAPI";
import { NewPassword, OnboardingUser } from "../../api/onboardingAPI";
import { User } from "../../api/usersAPI";
import {
  employeeOnboardingStep1,
  employeeOnboardingStep2,
  employeeOnboardingStep3,
  employerOnboardingStep1,
  employerOnboardingStep2,
  employerOnboardingStep3,
  employerOnboardingStep4,
  fetchUser,
  hideWelcomeMessage,
  onboardingGDPR,
  setCurrentStep,
  setSteps,
  showWelcomeMessage,
  usersOnboarding,
  verifyPhoneNumber,
} from "../actions/onboardingActions";
import { navigate } from "gatsby";

interface ErrorDetails {
  [key: string]: string[];
}

export interface Progress {
  steps: number;
  currentStep: number;
}

interface OnboardingState {
  token?: string;
  login?: {
    token: string;
    user: User;
  };
  user: OnboardingUser;
  company: Company;
  password: NewPassword;
  organizationStructure: OnboardingDepartament[];
  progress?: Progress;
  loading: boolean;
  hasUserBeenFetched: boolean;
  isPhoneNumberValid: boolean;
  isPhoneNumberValidating: boolean;
  error: boolean;
  errorMessage?: string;
  errorDetails?: ErrorDetails;
  showWelcomeMessage: boolean;
}

const initialState: OnboardingState = {
  loading: true,
  hasUserBeenFetched: false,
  isPhoneNumberValid: false,
  isPhoneNumberValidating: false,
  error: false,
  errorMessage: "",
  errorDetails: {},
  user: {
    id: 0,
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    isActive: false,
    isCompanyAdmin: false,
  },
  company: {
    address: "",
    domain: "",
    name: "",
  },
  password: {
    newPassword: "",
  },
  organizationStructure: [],
  showWelcomeMessage: false,
};

const handleReject = <T>(
  state: WritableDraft<OnboardingState>,
  action: ErrorPayloadAction<T>
) => {
  state.loading = false;

  if (action.payload) {
    state.error = action.payload.error;
    state.errorMessage = action.payload.message;
    state.errorDetails = action.payload.details;
  } else {
    state.error = true;
    state.errorMessage = undefined;
    state.errorDetails = undefined;
  }
};

const clearErrors = (state: WritableDraft<OnboardingState>) => {
  state.error = false;
  state.errorMessage = undefined;
  state.errorDetails = undefined;
};

const handlePending = (state: WritableDraft<OnboardingState>) => {
  state.loading = true;
  clearErrors(state);
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        handlePending(state);
        state.hasUserBeenFetched = false;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;

        if (!state.user.phoneNumber) {
          state.user.phoneNumber = "";
        }

        state.loading = false;
        state.hasUserBeenFetched = true;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        handleReject(state, action);
        state.hasUserBeenFetched = true;
        // navigate to the main app page if the onboarding token is rejected
        void navigate("/");
      })
      .addCase(usersOnboarding.pending, handlePending)
      .addCase(usersOnboarding.fulfilled, (state, action) => {
        state.login = action.payload;
        state.loading = false;
      })
      .addCase(usersOnboarding.rejected, handleReject)
      .addCase(verifyPhoneNumber.pending, (state) => {
        handlePending(state);
        state.isPhoneNumberValid = false;
        state.isPhoneNumberValidating = true;
      })
      .addCase(verifyPhoneNumber.fulfilled, (state, action) => {
        state.user.phoneNumber = action.meta.arg.phoneNumber;
        state.isPhoneNumberValid = true;
        state.isPhoneNumberValidating = true;
        state.loading = false;
      })
      .addCase(verifyPhoneNumber.rejected, (state, action) => {
        state.user.phoneNumber = action.meta.arg.phoneNumber;
        handleReject(state, action);
        state.isPhoneNumberValid = false;
        state.isPhoneNumberValidating = false;
      })
      .addCase(setSteps, (state, { payload: steps }) => {
        state.progress = { steps, currentStep: 1 };
      })
      .addCase(setCurrentStep, (state, { payload: currentStep }) => {
        if (state.progress) {
          state.progress.currentStep = currentStep;
        }
      })
      .addCase(employerOnboardingStep1, (state, { payload: company }) => {
        state.company = company;

        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(employerOnboardingStep2, (state, { payload: user }) => {
        state.user.firstName = user.firstName;
        state.user.lastName = user.lastName;
        state.user.phoneNumber = user.phoneNumber;
        state.isPhoneNumberValidating = false;

        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(employerOnboardingStep3, (state, { payload: newPassword }) => {
        state.password.newPassword = newPassword;

        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(employerOnboardingStep4, (state, { payload: structure }) => {
        state.organizationStructure = structure;

        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(employeeOnboardingStep1, (state) => {
        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(employeeOnboardingStep2, (state) => {
        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(employeeOnboardingStep3, (state) => {
        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(onboardingGDPR, (state) => {
        if (state.progress) {
          state.progress.currentStep += 1;
        }
      })
      .addCase(showWelcomeMessage, (state) => {
        state.showWelcomeMessage = true;
      })
      .addCase(hideWelcomeMessage, (state) => {
        state.showWelcomeMessage = false;
      });
  },
});

export default onboardingSlice.reducer;
