import { createAsyncThunk } from "@reduxjs/toolkit";
import client, {
  ApiResponse,
  AsyncThunkConfig,
} from "../../../api/axiosConfig";
import { ApiPosts, PostData, postsURL } from "../../../api/pulseAPI";
import { handleAxiosError } from "../../../utils/redux";
import { LimitOffsetFilters } from "../calendarFiltersActions";

export interface PostCreateData {
  body: string;
  medium?: File;
}

export interface PostEditData {
  id: number;
  body?: string;
  medium?: File | null;
}

export const createPost = createAsyncThunk<
  PostData,
  PostCreateData,
  AsyncThunkConfig
>("pulse/posts/create", async (post, thunkAPI) => {
  const formData = new FormData();

  formData.append("body", post.body);
  post.medium && formData.append("medium", post.medium);

  try {
    const response = await client.post<ApiResponse<PostData>>(postsURL, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
    });

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const editPost = createAsyncThunk<PostData, PostEditData, AsyncThunkConfig>(
  "pulse/posts/edit",
  async (post, thunkAPI) => {
    const formData = new FormData();

    post.body && formData.append("body", post.body);
    post.medium !== undefined &&
      formData.append("medium", post.medium ? post.medium : "");

    try {
      const response = await client.patch<ApiResponse<PostData>>(
        `${postsURL}${post.id}/`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" }, timeout: 0 }
      );

      return response.data.results;
    } catch (err) {
      return handleAxiosError(err, thunkAPI);
    }
  }
);

export const deletePost = createAsyncThunk<number, number, AsyncThunkConfig>(
  "pulse/posts/delete",
  async (id, thunkAPI) => {
    try {
      await client.delete(`${postsURL}${id}/`);

      return id;
    } catch (err) {
      return handleAxiosError(err, thunkAPI);
    }
  }
);

interface PostResponse extends ApiPosts {
  toAppend?: boolean;
  nextParams?: LimitOffsetFilters;
}

export const fetchPosts = createAsyncThunk<
  PostResponse,
  LimitOffsetFilters,
  AsyncThunkConfig
>("pulse/posts/fetch", async (params, thunkAPI) => {
  try {
    const response = await client.get<PostResponse>(postsURL, { params });

    if (params && params.limit && params.offset) {
      response.data.toAppend = true;
    }

    return response.data;
  } catch (error) {
    return handleAxiosError(error, thunkAPI);
  }
});
