import { createAsyncThunk } from "@reduxjs/toolkit";
import client, { AsyncThunkConfig } from "../../api/axiosConfig";
import { dispatchEnqueueSnackbar } from "./notificationsActions";
import { handleAxiosError } from "../../utils/redux";

export interface DisableUsersArgs {
  ids: number | number[];
}

export const disableUsers = createAsyncThunk<
  void,
  DisableUsersArgs,
  AsyncThunkConfig
>("users/disableUsers", async (args, thunkAPI) => {
  try {
    const response = await client.post("/api/users/delete/", {
      ids: args.ids,
    });

    dispatchEnqueueSnackbar({
      message: "User(s) disabled successfully",
    });
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
