import { MinifiedUser } from "./usersAPI";

export const postsURL = "/api/pulse/posts/";
export const commentsURL = "/api/pulse/comments/";

export interface PostData {
  id: number;
  owner: MinifiedUser;
  body: string;
  medium?: string;
  videoCodec?: string;
  thumbnail?: string;
  creationDate: string;
  likes: number[];
  commentsNum: number;
}

export interface ApiPosts {
  results: PostData[];
  count: number;
  next?: string;
  previous?: string;
}

export interface Comment {
  id: number;
  post: number;
  owner: MinifiedUser;
  body: string;
  creationDate: string;
}

export interface ApiComments {
  results: Comment[];
  count: number;
  next?: string;
  previous?: string;
}
