import { createSlice } from "@reduxjs/toolkit";
import {
  CalendarFilters,
  calendarSetFilters,
  calendarSetLimitOffset,
  calendarSetStartEndDate,
  calendarSetUserId,
} from "../actions/calendarFiltersActions";

const initialState: CalendarFilters = {};

const calendarFiltersSlice = createSlice({
  name: "calendarFilters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //  TODO: check if partial update works
      .addCase(calendarSetLimitOffset, (state, action) => {
        state.limit = action.payload.limit ?? undefined;
        state.offset = action.payload.offset ?? undefined;
      })
      .addCase(calendarSetStartEndDate, (state, action) => {
        state.startDate = action.payload.startDate;
        state.endDate = action.payload.endDate;
      })
      .addCase(calendarSetUserId, (state, action) => {
        state.userId = action.payload?.userId;
      })
      .addCase(calendarSetFilters, (state, action) => {
        state.limit = action.payload.limit ?? undefined;
        state.offset = action.payload.offset ?? undefined;
        state.startDate = action.payload.startDate;
        state.endDate = action.payload.endDate;
        state.userId = action.payload.userId;
      });
  },
});

export default calendarFiltersSlice.reducer;
