import { createSlice } from "@reduxjs/toolkit";
import { changeMode } from "../actions/desktopCalendarActions";

export interface DesktopCalendarState {
  mode: string;
}

const initialState: DesktopCalendarState = {
  mode: "month",
};

const desktopCalendarReducer = createSlice({
  name: "desktopCalendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeMode, (state, action) => {
      state.mode = action.payload;
    });
  },
});

export default desktopCalendarReducer.reducer;
