import { createSlice } from "@reduxjs/toolkit";
import { BulkUpload } from "../../api/bulkUploadAPI";
import { bulkUpload } from "../actions/bulkUploadActions";

interface BulkUploadState {
  results?: BulkUpload;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: BulkUploadState = {
  results: undefined,
  loading: false,
  error: false,
  errorMessage: "",
};

const bulkUploadSlice = createSlice({
  name: "bulkUpload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bulkUpload.fulfilled, (state, action) => {
        state.results = action.payload;
        state.loading = false;
      })
      .addCase(bulkUpload.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkUpload.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      });
  },
});

export default bulkUploadSlice.reducer;
