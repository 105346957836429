import { createSlice } from "@reduxjs/toolkit";
import { Comment } from "../../../api/pulseAPI";
import {
  addComment,
  CommentParams,
  deleteComment,
  editComment,
  fetchComments,
} from "../../actions/pulse/pulseCommentActions";
import { getParamsForNext, NextParams } from "../activitiesReducers";
import { WritableDraft } from "immer/dist/internal";
import { ApiError } from "../../../api/axiosConfig";

export interface CommentsState {
  comments: Comment[];
  nextParams?: NextParams;
  fetch: {
    loading: boolean;
    error: boolean;
    errorMessage?: string;
    errorDetails?: any;
  };
  CUD: {
    loading: boolean;
    error: boolean;
    errorMessage?: string;
    errorDetails?: any;
  };
}

const initialApiState = {
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const initialCommentsState: CommentsState = {
  comments: [],
  nextParams: undefined,
  fetch: {
    ...initialApiState,
  },
  CUD: {
    ...initialApiState,
  },
};

export interface PostCommentsState {
  comments: { [postId: number]: CommentsState };
}

const initialState: PostCommentsState = {
  comments: {},
};

const mapToUpdateComment = (
  comments: Comment[],
  updatedComment: Comment
): Comment[] => {
  return comments.map((comment) => {
    if (comment.id === updatedComment.id) {
      return updatedComment;
    }

    return comment;
  });
};

const handleCUDPending = (
  state: WritableDraft<PostCommentsState>,
  action: { meta: { arg: CommentParams } }
) => {
  if (!state.comments[action.meta.arg.post]) {
    // initialize post's comments state if not existing
    state.comments = {
      ...state.comments,
      [action.meta.arg.post]: {
        ...initialCommentsState,
        CUD: {
          ...initialApiState,
          loading: true,
        },
      },
    };
  } else {
    state.comments = {
      ...state.comments,
      [action.meta.arg.post]: {
        ...state.comments[action.meta.arg.post],
        CUD: {
          ...initialApiState,
          loading: true,
        },
      },
    };
  }
};

const handleCUDRejected = (
  state: WritableDraft<PostCommentsState>,
  action: {
    payload: { error: ApiError; [key: string]: any } | undefined;
    type: string;
  }
) => {
  if (action.payload) {
    state.comments = {
      ...state.comments,
      [action.payload.postId]: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ...state.comments[action.payload.postId],
        CUD: {
          loading: false,
          error: action.payload.error.error,
          errorMessage: action.payload.error.message,
          errorDetails: action.payload.error.details,
        },
      },
    };
  }
};

const pulseCommentsSlice = createSlice({
  name: "pulseComments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchComments.pending, (state, { meta: { arg } }) => {
        if (!state.comments[arg.post]) {
          // initialize post's comments state if not existing
          state.comments = {
            ...state.comments,
            [arg.post]: {
              ...initialCommentsState,
              fetch: {
                ...initialCommentsState.fetch,
                loading: true,
              },
            },
          };
        } else {
          state.comments = {
            ...state.comments,
            [arg.post]: {
              ...state.comments[arg.post],
              fetch: {
                ...state.comments[arg.post].fetch,
                loading: true,
              },
            },
          };
        }
      })
      .addCase(
        fetchComments.fulfilled,
        (state, { payload: { postId, data } }) => {
          const existingIds = new Set(
            state.comments[postId].comments.map((comment) => comment.id)
          );

          state.comments = {
            ...state.comments,
            [postId]: {
              ...state.comments[postId],
              comments: [
                ...state.comments[postId].comments,
                ...data.results.filter(
                  (comment) => !existingIds.has(comment.id)
                ),
              ], // merge comments without duplicates
              fetch: {
                ...state.comments[postId].fetch,
                loading: false,
              },
              nextParams: data.next ? getParamsForNext(data.next) : {},
            },
          };
        }
      )
      .addCase(fetchComments.rejected, (state, action) => {
        if (action.payload) {
          state.comments = {
            ...state.comments,
            [action.payload.postId]: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              ...state.comments[action.payload.postId],
              fetch: {
                loading: false,
                error: action.payload.error.error,
                errorMessage: action.payload.error.message,
                errorDetails: action.payload.error.details,
              },
            },
          };
        }
      })
      .addCase(deleteComment.pending, handleCUDPending)
      .addCase(
        deleteComment.fulfilled,
        (state, { payload: { postId, data } }) => {
          const { nextParams } = state.comments[postId];

          state.comments = {
            ...state.comments,
            [postId]: {
              ...state.comments[postId],
              comments: state.comments[postId].comments.filter(
                (comment) => comment.id !== data.commentId
              ),
              CUD: {
                ...state.comments[postId].CUD,
                loading: false,
              },
              nextParams:
                nextParams && nextParams.offset
                  ? { ...nextParams, offset: nextParams.offset - 1 }
                  : nextParams, // update the Fetching Offset with currently deleted comment - prevent gaps between "pages"
            },
          };
        }
      )
      .addCase(deleteComment.rejected, handleCUDRejected)
      .addCase(addComment.pending, handleCUDPending)
      .addCase(addComment.fulfilled, (state, { payload: { postId, data } }) => {
        const { nextParams } = state.comments[postId];

        state.comments = {
          ...state.comments,
          [postId]: {
            ...state.comments[postId],
            comments: [data, ...state.comments[postId].comments],
            CUD: {
              ...state.comments[postId].CUD,
              loading: false,
            },
            nextParams:
              nextParams && nextParams.offset
                ? { ...nextParams, offset: nextParams.offset + 1 }
                : nextParams, // update the Fetching Offset with currently created comment - prevent comments duplicates in paging
          },
        };
      })
      .addCase(addComment.rejected, handleCUDRejected)
      .addCase(editComment.pending, handleCUDPending)
      .addCase(
        editComment.fulfilled,
        (state, { payload: { postId, data } }) => {
          state.comments = {
            ...state.comments,
            [postId]: {
              ...state.comments[postId],
              comments: mapToUpdateComment(
                state.comments[postId].comments,
                data
              ),
              CUD: {
                ...state.comments[postId].CUD,
                loading: false,
              },
            },
          };
        }
      )
      .addCase(editComment.rejected, handleCUDRejected);
  },
});

export default pulseCommentsSlice.reducer;
