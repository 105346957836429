import { ApiResponse, AsyncThunkConfig } from "../../api/axiosConfig";
import client from "../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BulkUpload } from "../../api/bulkUploadAPI";
import { handleAxiosError } from "../../utils/redux";
import { dispatchEnqueueSnackbar } from "./notificationsActions";

export const bulkUpload = createAsyncThunk<BulkUpload, File, AsyncThunkConfig>(
  "users/bulkUpload",
  async (file, thunkAPI) => {
    try {
      const formData = new FormData();

      formData.append("file", file);

      const response = await client.put<ApiResponse<BulkUpload>>(
        "/api/users/bulk_upload/",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const { results } = response.data;

      dispatchEnqueueSnackbar({
        message: `Added users: ${results.addedUsersCount}. Failed users: ${results.failedUsersCount}.`,
      });

      return results;
    } catch (err) {
      return handleAxiosError(err, thunkAPI);
    }
  }
);
