import client, { ApiResponse, AsyncThunkConfig } from "../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ActivityType } from "../../api/activityTypesAPI";
import { handleAxiosError } from "../../utils/redux";

const activityTypeURL = "/api/core/activity-types/";

export const fetchActivityTypes = createAsyncThunk<
  ApiResponse<ActivityType[]>,
  void,
  AsyncThunkConfig
>("activityTypes/fetchActivityTypes", async (args, thunkAPI) => {
  try {
    const response = await client.request<ApiResponse<ActivityType[]>>({
      url: activityTypeURL,
    });

    return response.data;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
