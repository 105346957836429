import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client, { ApiResponse, AsyncThunkConfig } from "../../api/axiosConfig";
import {
  CreateDepartament,
  CreateTeamInDepartment,
  Departament,
  EditTeamInDepartment,
  Team,
  TeamInDepartment,
} from "../../api/departmentsAPI";
import { handleAxiosError } from "../../utils/redux";

export const clearDepartmentsErrors = createAction("departments/clearErrors");

export const fetchDepartments = createAsyncThunk<
  Departament[],
  void,
  AsyncThunkConfig
>("departments/fetchDepartments", async (_args, thunkAPI) => {
  try {
    const response = await client.request<ApiResponse<Departament[]>>({
      url: "/api/core/departments/",
    });

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const removeDepartment = createAsyncThunk<
  void,
  number,
  AsyncThunkConfig
>("departments/removeDepartment", async (id, thunkAPI) => {
  try {
    await client.delete(`/api/core/departments/${id}/`);
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const addDepartment = createAsyncThunk<
  Departament,
  CreateDepartament,
  AsyncThunkConfig
>("departments/addDepartment", async (department, thunkAPI) => {
  try {
    const response = await client.post<ApiResponse<Departament>>(
      `/api/core/departments/`,
      department
    );

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const addTeam = createAsyncThunk<
  Team,
  CreateTeamInDepartment,
  AsyncThunkConfig
>("departments/addTeam", async (team, thunkAPI) => {
  try {
    const response = await client.post<ApiResponse<Team>>(`/api/core/teams/`, {
      departmentId: team.departmentId,
      name: team.name,
      membersIds: team.members.map(({ id }) => id),
    });

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const removeTeam = createAsyncThunk<
  void,
  TeamInDepartment,
  AsyncThunkConfig
>("departments/removeTeam", async ({ departmentId, id }, thunkAPI) => {
  try {
    await client.delete(`/api/core/teams/${id}/`, {
      params: { departmentId },
    });
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const editTeam = createAsyncThunk<
  Team,
  EditTeamInDepartment,
  AsyncThunkConfig
>("departments/editTeam", async (team, thunkAPI) => {
  try {
    const response = await client.put<ApiResponse<Team>>(
      `/api/core/teams/${team.id}/`,
      {
        departmentId: team.departmentId,
        id: team.id,
        name: team.name,
        membersIds: team.members.map(({ id }) => id),
      }
    );

    return response.data.results;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
