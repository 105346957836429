import { Activity } from "../../api/activitiesAPI";
import { createSlice } from "@reduxjs/toolkit";
import { fetchActivities } from "../actions/activitiesActions";

export interface ActivitiesState {
  activities: Activity[];
  nextParams?: {
    limit?: number | null;
    offset?: number | null;
  };
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: ActivitiesState = {
  activities: [],
  nextParams: undefined,
  loading: true,
  error: false,
  errorMessage: "",
};

export interface NextParams {
  limit?: number | null;
  offset?: number | null;
}

export const getParamsForNext = (nextStr: string): NextParams => {
  const paramsStr = nextStr.substring(nextStr.indexOf("?"));
  const strParams = new URLSearchParams(paramsStr);

  const limitStr = strParams.get("limit");
  const offsetStr = strParams.get("offset");

  return {
    limit: limitStr ? parseInt(limitStr, 10) : null,
    offset: offsetStr ? parseInt(offsetStr, 10) : null,
  };
};

const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.fulfilled, (state, action) => {
        if (action.payload.toAppend) {
          state.activities = state.activities.concat(action.payload.results);
        } else {
          state.activities = action.payload.results;
        }

        if (action.payload.next) {
          state.nextParams = getParamsForNext(action.payload.next);
        } else {
          state.nextParams = {};
        }
        state.loading = false;
      })
      .addCase(fetchActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      });
  },
});

export default activitiesSlice.reducer;
