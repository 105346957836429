import { createSlice } from "@reduxjs/toolkit";
import {
  Notification,
  closeSnackbar,
  enqueueSnackbar,
  removeSnackbar,
} from "../actions/notificationsActions";
import { SnackbarKey } from "notistack";

interface NotificationState {
  key: SnackbarKey;
  notification: Notification;
  dismissed?: boolean;
}

const initialState: {
  notifications: NotificationState[];
} = { notifications: [] };

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(enqueueSnackbar, (state, action) => {
        const key = action.payload.notification.options?.key;

        if (key) {
          state.notifications = [
            ...state.notifications,
            { key, notification: action.payload.notification },
          ];
        }
      })
      .addCase(closeSnackbar, (state, action) => {
        state.notifications = state.notifications.map((notification) => {
          return notification.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification };
        });
      })
      .addCase(removeSnackbar, (state, action) => {
        state.notifications = state.notifications.filter(
          (notification) => notification.key !== action.payload.key
        );
      });
  },
});

export default notificationsSlice.reducer;
