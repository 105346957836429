import { combineReducers } from "@reduxjs/toolkit";
import activitiesReducer from "./activitiesReducers";
import activityTypesReducers from "./activityTypesReducers";
import authReducer from "./authReducers";
import userReducer from "./usersReducers";
import departmentsReducer from "./departmentsReducers";
import bulkUploadReducer from "./bulkUploadReducers";
import companyReducer from "./companyReducers";
import onboardingReducer from "./onboardingReducers";
import resetPasswordEmailReducer from "./passwordResetEmailReducers";
import resetPasswordConfirmReducer from "./passwordResetConfirmReducers";
import activityCRUDReducers from "./activityCRUDReducers";
import networkUsersReducers from "./networkUsersReducers";
import activityFormReducers from "./activityFormReducers";
import passwordChangeReducers from "./passwordChangeReducers";
import activityPreferencesReducer from "./activityPreferencesReducers";
import calendarFiltersReducer from "./calendarFiltersReducers";
import notificationsReducer from "./notificationsReducers";
import userDisablingReducers from "./userDisablingReducers";
import desktopCalendarReducers from "./desktopCalendarReducers";
import pulsePostReducers from "./pulse/pulsePostReducers";
import pulseCommentsReducers from "./pulse/pulseCommentReducers";

export default combineReducers({
  users: userReducer,
  networkUsers: networkUsersReducers,
  authUser: authReducer,
  activities: activitiesReducer,
  activityForm: activityFormReducers,
  activityCRUD: activityCRUDReducers,
  activityTypes: activityTypesReducers,
  departments: departmentsReducer,
  bulkUpload: bulkUploadReducer,
  company: companyReducer,
  onboarding: onboardingReducer,
  resetPasswordEmail: resetPasswordEmailReducer,
  resetPasswordConfirm: resetPasswordConfirmReducer,
  passwordChange: passwordChangeReducers,
  activityPreferences: activityPreferencesReducer,
  calendarFilters: calendarFiltersReducer,
  notifications: notificationsReducer,
  userDisabling: userDisablingReducers,
  desktopCalendar: desktopCalendarReducers,
  pulsePosts: pulsePostReducers,
  pulseComments: pulseCommentsReducers,
});
