import { createSlice } from "@reduxjs/toolkit";
import { changePassword } from "../actions/passwordChangeActions";

export interface passwordChange {
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  errorDetails?: any;
}

const initialState: passwordChange = {
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const passwordChangeSlice = createSlice({
  name: "passwordChange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
          state.errorDetails = action.payload.details;
        } else {
          state.error = true;
          state.errorMessage = undefined;
          state.errorDetails = undefined;
        }
      });
  },
});

export default passwordChangeSlice.reducer;
