import { createAction } from "@reduxjs/toolkit";
import { Omit, OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import store from "../store";
export interface Notification {
  message: SnackbarMessage;
  options?: Omit<OptionsObject, "defaultValue">;
}

export const enqueueSnackbar = createAction<{ notification: Notification }>(
  "notifications/enqueueSnackbar"
);

export const closeSnackbar = createAction<{ key: SnackbarKey }>(
  "notifications/closeSnackbar"
);

export const removeSnackbar = createAction<{ key: SnackbarKey }>(
  "notifications/removeSnackbar"
);

export const dispatchEnqueueSnackbar = ({ message, options }: Notification) => {
  const { dispatch } = store;

  dispatch(
    enqueueSnackbar({
      notification: {
        message,
        options: {
          ...options,
          key: new Date().getTime() + Math.random(),
        },
      },
    })
  );
};
