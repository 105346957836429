import { createSlice } from "@reduxjs/toolkit";
import { setActivityTypePreferences } from "../actions/activityPreferencesActions";

export interface ActivityPreferences {
  processed: boolean;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  errorDetails?: any;
}

const initialState: ActivityPreferences = {
  processed: false,
  loading: false,
  error: false,
  errorMessage: undefined,
  errorDetails: undefined,
};

const activityPreferencesSlice = createSlice({
  name: "activityPreferences",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setActivityTypePreferences.pending, (state) => {
        state.processed = false;
        state.loading = true;
        state.error = false;
        state.errorMessage = undefined;
        state.errorDetails = undefined;
      })
      .addCase(setActivityTypePreferences.fulfilled, (state) => {
        state.processed = true;
        state.loading = false;
      })
      .addCase(setActivityTypePreferences.rejected, (state, action) => {
        state.processed = true;
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
          state.errorDetails = action.payload.details;
        } else {
          state.error = true;
          state.errorMessage = undefined;
          state.errorDetails = undefined;
        }
      });
  },
});

export default activityPreferencesSlice.reducer;
