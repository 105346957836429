import { AsyncThunkConfig } from "../../api/axiosConfig";
import { ApiUsers, UsersParams } from "../../api/usersAPI";
import client from "../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleAxiosError } from "../../utils/redux";

export interface FetchUsersArgs {
  pageLimit?: number;
  nextPageParam?: number;
  activityFilter?: string | number;
  departmentFilter?: string | number;
}

const extractFetchParams = (args: FetchUsersArgs): UsersParams => {
  const params: UsersParams = {};

  if (args.pageLimit) {
    params.limit = args.pageLimit;

    if (args.nextPageParam) {
      params.offset = args.pageLimit * (args.nextPageParam - 1);
    }
  }

  if (args.activityFilter && args.activityFilter !== "all") {
    if (Number.isInteger(args.activityFilter)) {
      params.activityTypeId = Number(args.activityFilter);
    }
  }

  if (args.departmentFilter && args.departmentFilter !== "all") {
    if (Number.isInteger(args.departmentFilter)) {
      params.departmentId = Number(args.departmentFilter);
    }
  }

  return params;
};

export const fetchUsers = createAsyncThunk<
  ApiUsers,
  FetchUsersArgs,
  AsyncThunkConfig
>("users/fetchUsers", async (args, thunkAPI) => {
  const params = extractFetchParams(args);

  try {
    const response = await client.request<ApiUsers>({
      url: "/api/users/",
      params,
    });

    return response.data;
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
