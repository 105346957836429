import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { Company } from "../../api/companyAPI";
import { editCompany, fetchCompany } from "../actions/companyActions";
import { ErrorPayloadAction } from "../../utils/redux";

interface ErrorDetails {
  [key: string]: string[];
}
interface CompanyState {
  company: Company;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  errorDetails?: ErrorDetails;
}

const initialState: CompanyState = {
  company: { name: "", address: "", domain: "" },
  loading: true,
  error: false,
  errorMessage: "",
  errorDetails: {},
};

const handleReject = <T>(
  state: WritableDraft<CompanyState>,
  action: ErrorPayloadAction<T>
) => {
  state.loading = false;

  if (action.payload) {
    state.error = action.payload.error;
    state.errorMessage = action.payload.message;
    state.errorDetails = action.payload.details;
  } else {
    state.error = true;
    state.errorMessage = undefined;
    state.errorDetails = undefined;
  }
};

const clearErrors = (state: WritableDraft<CompanyState>) => {
  state.error = false;
  state.errorMessage = undefined;
  state.errorDetails = undefined;
};

const handlePending = (state: WritableDraft<CompanyState>) => {
  state.loading = true;
  clearErrors(state);
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.pending, handlePending)
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.company = action.payload;
        state.loading = false;
      })
      .addCase(fetchCompany.rejected, handleReject)
      .addCase(editCompany.pending, handlePending)
      .addCase(editCompany.fulfilled, (state, action) => {
        state.company = action.payload;
        state.loading = false;
      })
      .addCase(editCompany.rejected, handleReject);
  },
});

export default companySlice.reducer;
