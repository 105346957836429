import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { PostData } from "../../../api/pulseAPI";
import {
  createPost,
  deletePost,
  editPost,
  fetchPosts,
} from "../../actions/pulse/pulsePostActions";
import { ErrorPayloadAction } from "../../../utils/redux";

import { getParamsForNext, NextParams } from "../activitiesReducers";

interface PostsState {
  posts: PostData[];
  toAppend: boolean;
  nextParams?: NextParams;
  fetch: {
    loading: boolean;
    error: boolean;
    errorMessage?: string;
    errorDetails?: any;
  };
  CUD: {
    // Create Update Delete state
    loading: boolean;
    error: boolean;
    errorMessage?: string;
    errorDetails?: any;
  };
}

const initialState: PostsState = {
  posts: [],
  toAppend: false,
  nextParams: {offset:0},
  fetch: {
    loading: false,
    error: false,
    errorMessage: "",
    errorDetails: {},
  },
  CUD: {
    loading: true,
    error: false,
    errorMessage: "",
    errorDetails: {},
  },
};

const clearCUDErrors = (state: WritableDraft<PostsState>) => {
  state.CUD.error = false;
  state.CUD.errorMessage = undefined;
  state.CUD.errorDetails = undefined;
};

export const handleCUDPending = (
  state: WritableDraft<PostsState>
) => {
  state.CUD.loading = true;
  clearCUDErrors(state);
};

export const handleCUDRejected = <T>(
  state: WritableDraft<PostsState>,
  action: ErrorPayloadAction<T>
) => {
  state.CUD.loading = false;

  if (action.payload) {
    state.CUD.error = action.payload.error;
    state.CUD.errorMessage = action.payload.message;
    state.CUD.errorDetails = action.payload.details;
  } else {
    state.CUD.error = true;
    state.CUD.errorMessage = undefined;
    state.CUD.errorDetails = undefined;
  }
};

const mapToUpdatedPosts = (posts: PostData[], updatedPost: PostData): PostData[] => {
  return posts.map((post) => {
    if (post.id === updatedPost.id) {
      return updatedPost;
    }

    return post;
  });
};

const pulsePostsSlice = createSlice({
  name: "pulsePosts",
  initialState,
  reducers: {
    updateSinglePostData(state, action: PayloadAction<PostData>) {
      state.posts = mapToUpdatedPosts(state.posts, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPost.pending, handleCUDPending)
      .addCase(createPost.fulfilled, (state, action) => {
        state.posts.unshift(action.payload); // add just created Post's data to the fetched posts list
        state.CUD.loading = false;

        if (state.nextParams && (state.nextParams.offset || state.nextParams.offset === 0)) {
          state.nextParams = {...state.nextParams, offset: state.nextParams.offset + 1}
        } // update the Fetching Offset with currently added post - prevent overlapping pages (posts duplicates between the pages)
      })
      .addCase(createPost.rejected, handleCUDRejected)
      .addCase(editPost.pending, handleCUDPending)
      .addCase(editPost.fulfilled, (state, action) => {
        // update fetched posts list with the modified post's data
        state.posts = mapToUpdatedPosts(state.posts, action.payload);
        state.CUD.loading = false;
      })
      .addCase(editPost.rejected, handleCUDRejected)
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.posts = action.payload.toAppend
          ? state.posts.concat(action.payload.results)
          : action.payload.results;

        state.nextParams = action.payload.next
          ? getParamsForNext(action.payload.next)
          : undefined;

        state.fetch.loading = false;
      })
      .addCase(fetchPosts.pending, (state) => {
        state.fetch.loading = true;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.fetch.loading = false;

        if (action.payload) {
          state.fetch.error = action.payload.error;
          state.fetch.errorMessage = action.payload.message;
        } else {
          state.fetch.error = true;
          state.fetch.errorMessage = undefined;
        }
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.posts = state.posts.filter((post) => post.id !== action.payload);
        state.CUD.loading = false;

        if (state.nextParams && state.nextParams.offset) {
          state.nextParams = {...state.nextParams, offset:state.nextParams.offset - 1}
        } // update the Fetching Offset with currently deleted post - prevent gaps between pages (posts not present on either of the pages)
      })
      .addCase(deletePost.pending, (state) => handleCUDPending(state))
      .addCase(deletePost.rejected, handleCUDRejected);
  },
});

export const { updateSinglePostData } = pulsePostsSlice.actions;
export default pulsePostsSlice.reducer;
