import { navigate } from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { theme } from "../styles/mui/theme";
import {
  activityIconsMapping0C3DEB,
  activityIconsMapping97A1B0,
  activityIconsMappingWhite,
} from "./icons";
import { MinifiedUser, User } from "../api/usersAPI";

const MOTKO_LANDING_PAGE = "https://www.motko.co/";

export const customActivityTypeName = "Custom";

export const fetchActivityIcon = (activityType: string, variant?: string) => {
  switch (variant) {
  case "white":
    return activityIconsMappingWhite[activityType];
  case "blue":
  case "#0C3DEB":
    return activityIconsMapping0C3DEB[activityType];
  case "grey":
  case "#97A1B0":
    return activityIconsMapping97A1B0[activityType];
  default:
    return activityIconsMappingWhite[activityType];
  }
};

export const isToday = (date: Date) => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isBrowser = typeof window !== "undefined";

/**
 * Function preventing access to the application for the "public" tenant in the web environments
 * (stage & prod) by redirecting all requests which hostname points at "app." subdomain.
 * Note: "public" tenant handles only backend operations.
 */
export const preventBaseTenantExposure = () => {
  if (isBrowser && window.location.hostname.startsWith("app.")) {
    void navigate(MOTKO_LANDING_PAGE);
  }

  return null;
};

export const currWindowWidth = () => {
  const { innerWidth: width } = window;

  return width;
};

export const pickedTime2Datetime = (
  startDate: string | Date,
  startTime: string,
  endTime: string
) => {
  const sTime = startTime.split(":").map(Number);
  const start = new Date(startDate).setHours(sTime[0], sTime[1]);

  const eTime = endTime.split(":").map(Number);
  const end = new Date(startDate).setHours(eTime[0], eTime[1]);

  return {
    startDate: new Date(start),
    endDate: new Date(end),
  };
};

export const useIsSmall = (
  resolution: number = theme.breakpoints.values.md - 1
): boolean => useMediaQuery(`(max-width: ${resolution}px)`);

export const useIsXSmall = (
  resolution: number = theme.breakpoints.values.sm - 1
): boolean => useMediaQuery(`(max-width: ${resolution}px)`);

/*
 * Method mapping users' full names to User IDs
 */
export const getUserIds = (fullNames: string[], userData: User[]) => {
  const ids: number[] = [];

  if (fullNames.length > 0) {
    fullNames.forEach((fullName) => {
      const [firstName, lastName] = fullName.split(" ");
      const fullFoundUser = userData.find(
        (fullUser) =>
          fullUser.firstName === firstName && fullUser.lastName === lastName
      );

      if (fullFoundUser) {
        ids.push(fullFoundUser.id);
      }
    });
  }

  return ids;
};

export const getProperCSVFile = (
  file?: File | null,
  content?: string
): File | null => {
  if (!file || !content) {
    return null;
  }

  const firstLine = content.split("\n").shift();

  if (firstLine?.trim() === "E-mail,First Name,Last Name,Phone Number,Role") {
    return file;
  } else if (
    firstLine?.trim() === "E-mail;First Name;Last Name;Phone Number;Role"
  ) {
    //  handle windows Europe region csv settings
    return new File([content.replaceAll(";", ",")], "employees.csv");
  }

  return null;
};

export const isUserAllowedInActivity = (
  activityClosed: boolean,
  ownerId: number,
  attendees: MinifiedUser[],
  currUserId?: number
) => {
  if (activityClosed) {
    if (currUserId) {
      if (currUserId === ownerId) {
        return true;
      }

      return !!attendees.find((attendee) => {
        return attendee.id === currUserId;
      });
    }

    return false;
  }

  return true;
};

export const executeScroll = (smooth: boolean, elementId?: string) => {
  if (elementId) {
    const elementToScroll = document.getElementById(elementId);

    if (elementToScroll) {
      elementToScroll.scrollIntoView({
        block: "center",
        behavior: smooth ? "smooth" : "auto",
      });
    }
  } else {
    window.scrollTo({ top: 0, behavior: smooth ? "smooth" : "auto" });
  }
};
