import { AsyncThunkConfig } from "../../api/axiosConfig";
import client from "../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { dispatchEnqueueSnackbar } from "./notificationsActions";
import { handleAxiosError } from "../../utils/redux";

export type PasswordResetData = {
  email: string;
};

export type PasswordResetConfirmData = {
  newPassword: string;
  token: string;
};

export const resetPasswordSendEmail = createAsyncThunk<
  void,
  PasswordResetData,
  AsyncThunkConfig
>("users/password_reset", async (data, thunkAPI) => {
  try {
    await client.post("/api/users/reset_password/", data);

    dispatchEnqueueSnackbar({
      message: "Reset Email Sent!",
    });
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});

export const resetPasswordConfirm = createAsyncThunk<
  void,
  PasswordResetConfirmData,
  AsyncThunkConfig
>("users/password_reset/confirm", async (data, thunkAPI) => {
  try {
    await client.post("/api/users/reset_password/confirm/", data);

    dispatchEnqueueSnackbar({
      message: "Your password was reset!",
    });
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
