import { ApiError, isAxiosError } from "../api/axiosConfig";
import { dispatchEnqueueSnackbar } from "../redux/actions/notificationsActions";
import { PayloadAction, SerializedError } from "@reduxjs/toolkit";

export interface PayloadActionMeta<T> {
  arg: T;
  requestId: string;
  rejectedWithValue: boolean;
  requestStatus: "rejected";
  aborted: boolean;
  condition: boolean;
}

export type ErrorPayloadAction<T> = PayloadAction<
  ApiError | undefined,
  string,
  PayloadActionMeta<T>,
  SerializedError
>;

export const dispatchGeneralErrorNotification = () =>
  dispatchEnqueueSnackbar({
    message:
      "Your request failed due to unrecognized error. Please check your internet connection.",
  });

interface RejectWithApiError {
  readonly payload: ApiError;
  name: string;
  message: string;
}

type ThunkAPI = {
  rejectWithValue(value: ApiError): RejectWithApiError;
};

/*
 * Function handling axios errors in async thunks. Dispatches snackbar with error message
 */
export const handleAxiosError = (
  err: Error,
  thunkAPI: ThunkAPI
): RejectWithApiError => {
  if (isAxiosError(err) && err.response != null) {
    const errResp = err.response.data as ApiError;

    // show error message to the user
    dispatchEnqueueSnackbar({
      message: `Your request failed: ${errResp.message}`,
    });

    return thunkAPI.rejectWithValue(errResp);
  }

  // show general error message to the user
  dispatchGeneralErrorNotification();
  throw err;
};

interface RejectWithApiErrorExtended {
  readonly payload: {error: ApiError, [key: string]: any};
  name: string;
  message: string;
}

/*
 * Function handling axios errors in async thunks with additional data. Dispatches snackbar with error message.
 */
export const handleExtendedAxiosError = (
  errData: {err: Error; extendedPayload: { [key: string]: any }},
  thunkAPI: {rejectWithValue(value: {error: ApiError, [key: string]: any}): RejectWithApiErrorExtended; }
): RejectWithApiErrorExtended => {
  if (isAxiosError(errData.err) && errData.err.response != null) {
    const errResp = errData.err.response.data as ApiError;

    // show error message to the user
    dispatchEnqueueSnackbar({
      message: `Your request failed: ${errResp.message}`,
    });

    return thunkAPI.rejectWithValue({error: errResp, ...errData.extendedPayload});
  }

  // show general error message to the user
  dispatchGeneralErrorNotification();

  return thunkAPI.rejectWithValue(
    {
      error: {
        results: null,
        error: true,
        message: "Unrecognized error."
      },
      ...errData.extendedPayload})
};


export const simplyHandleAxiosError = (
  err: Error,
  thunkAPI: ThunkAPI
): RejectWithApiError => {
  if (isAxiosError(err) && err.response != null) {
    return thunkAPI.rejectWithValue(err.response.data as ApiError);
  }

  throw err;
};
