import { createSlice } from "@reduxjs/toolkit";
import {
  createActivity,
  deleteActivity,
  updateActivity,
  updateActivityStatus,
} from "../actions/activityCRUDActions";

export interface ActivityCRUDState {
  processed: boolean;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: ActivityCRUDState = {
  processed: false,
  loading: false,
  error: false,
  errorMessage: undefined,
};

const activityCRUDSlice = createSlice({
  name: "activityCRUD",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createActivity.fulfilled, (state, action) => {
        state.processed = true;
        state.loading = false;
      })
      .addCase(createActivity.pending, (state) => {
        state.loading = true;
        state.processed = false;
        state.error = false;
      })
      .addCase(createActivity.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        state.processed = true;
        state.loading = false;
      })
      .addCase(updateActivity.pending, (state) => {
        state.loading = true;
        state.processed = false;
        state.error = false;
      })
      .addCase(updateActivity.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      })
      .addCase(deleteActivity.fulfilled, (state, action) => {
        state.processed = true;
        state.loading = false;
      })
      .addCase(deleteActivity.pending, (state) => {
        state.loading = true;
        state.processed = false;
        state.error = false;
      })
      .addCase(deleteActivity.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      })
      .addCase(updateActivityStatus.fulfilled, (state) => {
        state.processed = true;
        state.loading = false;
      })
      .addCase(updateActivityStatus.pending, (state) => {
        state.loading = true;
        state.processed = false;
        state.error = false;
      })
      .addCase(updateActivityStatus.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      });
  },
});

export default activityCRUDSlice.reducer;
