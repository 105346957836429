import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { ErrorPayloadAction } from "../../utils/redux";
import { Departament } from "../../api/departmentsAPI";
import {
  addDepartment,
  addTeam,
  clearDepartmentsErrors,
  editTeam,
  fetchDepartments,
  removeDepartment,
  removeTeam,
} from "../actions/departmentsActions";

interface ErrorDetails {
  [key: string]: string[];
}
interface DepartmentsState {
  departments: Departament[];
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  errorDetails?: ErrorDetails;
}

const initialState: DepartmentsState = {
  departments: [],
  loading: true,
  error: false,
  errorMessage: "",
  errorDetails: {},
};

const handleReject = <T>(
  state: WritableDraft<DepartmentsState>,
  action: ErrorPayloadAction<T>
) => {
  state.loading = false;

  if (action.payload) {
    state.error = action.payload.error;
    state.errorMessage = action.payload.message;
    state.errorDetails = action.payload.details;
  } else {
    state.error = true;
    state.errorMessage = undefined;
    state.errorDetails = undefined;
  }
};

const clearErrors = (state: WritableDraft<DepartmentsState>) => {
  state.error = false;
  state.errorMessage = undefined;
  state.errorDetails = undefined;
};

const handlePending = (state: WritableDraft<DepartmentsState>) => {
  state.loading = true;
  clearErrors(state);
};

const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearDepartmentsErrors, (state) => {
        clearErrors(state);
      })
      .addCase(fetchDepartments.pending, handlePending)
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.departments = action.payload;
        state.loading = false;
      })
      .addCase(fetchDepartments.rejected, handleReject)
      .addCase(removeDepartment.pending, handlePending)
      .addCase(removeDepartment.fulfilled, (state, action) => {
        state.departments = state.departments.filter(
          ({ id }) => id !== action.meta.arg
        );
        state.loading = false;
      })
      .addCase(removeDepartment.rejected, handleReject)
      .addCase(addDepartment.pending, handlePending)
      .addCase(addDepartment.fulfilled, (state, action) => {
        state.departments = [...state.departments, action.payload];

        state.loading = false;
      })
      .addCase(addDepartment.rejected, handleReject)
      .addCase(addTeam.pending, handlePending)
      .addCase(addTeam.fulfilled, (state, action) => {
        const department = state.departments.find(
          ({ id }) => action.meta.arg.departmentId === id
        );

        if (department) {
          department.teams = [...department.teams, action.payload];
        }

        state.loading = false;
      })
      .addCase(addTeam.rejected, handleReject)
      .addCase(removeTeam.pending, handlePending)
      .addCase(removeTeam.fulfilled, (state, action) => {
        const department = state.departments.find(
          ({ id }) => action.meta.arg.departmentId === id
        );

        if (department) {
          department.teams = department.teams.filter(
            ({ id }) => id !== action.meta.arg.id
          );
        }

        state.loading = false;
      })
      .addCase(removeTeam.rejected, handleReject)
      .addCase(editTeam.pending, handlePending)
      .addCase(editTeam.fulfilled, (state, action) => {
        const department = state.departments.find(
          ({ id }) => action.meta.arg.departmentId === id
        );

        if (department) {
          const teamIndex = department.teams.findIndex(
            ({ id }) => action.meta.arg.id === id
          );

          department.teams[teamIndex] = action.payload;
        }

        state.loading = false;
      })
      .addCase(editTeam.rejected, handleReject);
  },
});

export default departmentsSlice.reducer;
