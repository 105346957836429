import { AsyncThunkConfig } from "../../api/axiosConfig";
import client from "../../api/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { dispatchEnqueueSnackbar } from "./notificationsActions";
import { handleAxiosError } from "../../utils/redux";

export interface PasswordChangeData {
  passwords: Passwords;
  userId: number;
}

export interface Passwords {
  currPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const changePassword = createAsyncThunk<
  void,
  PasswordChangeData,
  AsyncThunkConfig
>(`changePassword`, async (data, thunkAPI) => {
  try {
    await client.post(`/api/users/${data.userId}/set_password/`, {
      currPassword: data.passwords.currPassword,
      newPassword: data.passwords.newPassword,
    });

    dispatchEnqueueSnackbar({
      message: "Password changed.",
    });
  } catch (err) {
    return handleAxiosError(err, thunkAPI);
  }
});
