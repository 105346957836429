import axios, { AxiosError } from "axios";
import { logout } from "../redux/actions/authActions";
import { logoutURL } from "../utils/auth";
import store from "../redux/store";
import { authTokenItemName } from "../utils/auth";

const UNAUTHORIZED = 401;

export interface ApiError {
  results: null;
  error: boolean;
  message: string;
  details?: any;
}

export interface ApiResponse<T> {
  results: T;
}

export interface AsyncThunkConfig {
  rejectValue: ApiError;
}

export interface ExtendedAsyncThunkConfig {
  rejectValue: { error: ApiError; [key: string]: any; }
}

export interface PaginatedApiResponse {
  count: number;
  next?: string;
  previous?: string;
}

export function isAxiosError(error: any): error is AxiosError {
  return (error as AxiosError).isAxiosError;
}

const instance = axios.create({
  baseURL: process.env.API_URL ? process.env.API_URL : undefined,
  timeout: 30000,
});

// attach Auth Token to the requests
instance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem(authTokenItemName);

  if (authToken != null) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    config.headers.Authorization = `Token ${authToken}`;
  }

  return config;
});

// handle 401 Unauthorized responses
instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (
      error.response?.status === UNAUTHORIZED &&
      // prevent dispatching logout() action for a failed logout request
      !error.response?.config.url?.includes(logoutURL)
    ) {
      const { dispatch } = store;

      void dispatch(logout());

      return;
    }

    return Promise.reject(error);
  }
);

export default instance;
