import { User } from "../../api/usersAPI";
import { createSlice } from "@reduxjs/toolkit";
import { fetchUsers } from "../actions/userActions";

export interface usersState {
  users: User[];
  userCount: number;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: usersState = {
  users: [],
  userCount: 0,
  loading: true,
  error: false,
  errorMessage: "",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload.results;
        state.userCount = action.payload.count || 0;
        state.loading = false;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.error = action.payload.error;
          state.errorMessage = action.payload.message;
        } else {
          state.error = true;
          state.errorMessage = undefined;
        }
      });
  },
});

export default usersSlice.reducer;
