import BasketballIcon0C3DEB from "../icons/activities/0C3DEB/basketball.svg";
import CrossIcon0C3DEB from "../icons/activities/0C3DEB/cross.svg";
import RunningIcon0C3DEB from "../icons/activities/0C3DEB/running.svg";
import BoxingIcon0C3DEB from "../icons/activities/0C3DEB/boxing.svg";
import TennisIcon0C3DEB from "../icons/activities/0C3DEB/tennis.svg";
import GymIcon0C3DEB from "../icons/activities/0C3DEB/gym.svg";
import GolfIcon0C3DEB from "../icons/activities/0C3DEB/golf.svg";
import HiitIcon0C3DEB from "../icons/activities/0C3DEB/hiit.svg";
import KickboxingIcon0C3DEB from "../icons/activities/0C3DEB/kickboxing.svg";
import WalkingIcon0C3DEB from "../icons/activities/0C3DEB/walking.svg";
import SoccerIcon0C3DEB from "../icons/activities/0C3DEB/soccer.svg";
import VolleyballIcon0C3DEB from "../icons/activities/0C3DEB/volleyball.svg";
import SquashIcon0C3DEB from "../icons/activities/0C3DEB/squash.svg";
import CyclingIcon0C3DEB from "../icons/activities/0C3DEB/cycling.svg";
import YogaIcon0C3DEB from "../icons/activities/0C3DEB/yoga.svg";
import BasketballIconWhite from "../icons/activities/white/basketball.svg";
import CrossIconWhite from "../icons/activities/white/cross.svg";
import RunningIconWhite from "../icons/activities/white/running.svg";
import BoxingIconWhite from "../icons/activities/white/boxing.svg";
import TennisIconWhite from "../icons/activities/white/tennis.svg";
import GymIconWhite from "../icons/activities/white/gym.svg";
import GolfIconWhite from "../icons/activities/white/golf.svg";
import HiitIconWhite from "../icons/activities/white/hiit.svg";
import KickboxingIconWhite from "../icons/activities/white/kickboxing.svg";
import WalkingIconWhite from "../icons/activities/white/walking.svg";
import SoccerIconWhite from "../icons/activities/white/soccer.svg";
import VolleyballIconWhite from "../icons/activities/white/volleyball.svg";
import SquashIconWhite from "../icons/activities/white/squash.svg";
import CyclingIconWhite from "../icons/activities/white/cycling.svg";
import YogaIconWhite from "../icons/activities/white/yoga.svg";
import BasketballIcon97A1B0 from "../icons/activities/97A1B0/basketball.svg";
import CrossIcon97A1B0 from "../icons/activities/97A1B0/cross.svg";
import RunningIcon97A1B0 from "../icons/activities/97A1B0/running.svg";
import BoxingIcon97A1B0 from "../icons/activities/97A1B0/boxing.svg";
import TennisIcon97A1B0 from "../icons/activities/97A1B0/tennis.svg";
import GymIcon97A1B0 from "../icons/activities/97A1B0/gym.svg";
import GolfIcon97A1B0 from "../icons/activities/97A1B0/golf.svg";
import HiitIcon97A1B0 from "../icons/activities/97A1B0/hiit.svg";
import KickboxingIcon97A1B0 from "../icons/activities/97A1B0/kickboxing.svg";
import WalkingIcon97A1B0 from "../icons/activities/97A1B0/walking.svg";
import SoccerIcon97A1B0 from "../icons/activities/97A1B0/soccer.svg";
import VolleyballIcon97A1B0 from "../icons/activities/97A1B0/volleyball.svg";
import SquashIcon97A1B0 from "../icons/activities/97A1B0/squash.svg";
import CyclingIcon97A1B0 from "../icons/activities/97A1B0/cycling.svg";
import YogaIcon97A1B0 from "../icons/activities/97A1B0/yoga.svg";

export interface ActivityIconsMapping {
  [key: string]: string;
}

export const activityIconsMappingWhite: ActivityIconsMapping = {
  "Basketball": BasketballIconWhite,
  "Cross Training": CrossIconWhite,
  "Running": RunningIconWhite,
  "Boxing": BoxingIconWhite,
  "Tennis": TennisIconWhite,
  "Gym": GymIconWhite,
  "Golf": GolfIconWhite,
  "HIIT": HiitIconWhite,
  "Kickboxing": KickboxingIconWhite,
  "Walking": WalkingIconWhite,
  "Soccer": SoccerIconWhite,
  "Volleyball": VolleyballIconWhite,
  "Squash": SquashIconWhite,
  "Cycling": CyclingIconWhite,
  "Yoga": YogaIconWhite,
};

export const activityIconsMapping0C3DEB: ActivityIconsMapping = {
  "Basketball": BasketballIcon0C3DEB,
  "Cross Training": CrossIcon0C3DEB,
  "Running": RunningIcon0C3DEB,
  "Boxing": BoxingIcon0C3DEB,
  "Tennis": TennisIcon0C3DEB,
  "Gym": GymIcon0C3DEB,
  "Golf": GolfIcon0C3DEB,
  "HIIT": HiitIcon0C3DEB,
  "Kickboxing": KickboxingIcon0C3DEB,
  "Walking": WalkingIcon0C3DEB,
  "Soccer": SoccerIcon0C3DEB,
  "Volleyball": VolleyballIcon0C3DEB,
  "Squash": SquashIcon0C3DEB,
  "Cycling": CyclingIcon0C3DEB,
  "Yoga": YogaIcon0C3DEB,
};

export const activityIconsMapping97A1B0: ActivityIconsMapping = {
  "Basketball": BasketballIcon97A1B0,
  "Cross Training": CrossIcon97A1B0,
  "Running": RunningIcon97A1B0,
  "Boxing": BoxingIcon97A1B0,
  "Tennis": TennisIcon97A1B0,
  "Gym": GymIcon97A1B0,
  "Golf": GolfIcon97A1B0,
  "HIIT": HiitIcon97A1B0,
  "Kickboxing": KickboxingIcon97A1B0,
  "Walking": WalkingIcon97A1B0,
  "Soccer": SoccerIcon97A1B0,
  "Volleyball": VolleyballIcon97A1B0,
  "Squash": SquashIcon97A1B0,
  "Cycling": CyclingIcon97A1B0,
  "Yoga": YogaIcon97A1B0,
};
